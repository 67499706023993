import {useRef, useState} from "react";

interface Loader<T> {
  readonly loading: boolean
  readonly loaded: boolean
  readonly value?: T
  readonly error?: Error
  readonly load: (key: string, fn: (check: () => void) => Promise<T>) => void
  readonly key: string;
}

const NOT_RELEVANT_ANYMORE_ERROR = new Error("not relevant anymore");

export function useLoader<T>(): Loader<T> {
  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<T | undefined>();
  const [err, setErr] = useState<Error | undefined>();
  const currentRequestId = useRef(-1);

  return {
    loading,
    loaded: !loading,
    value,
    error: err,
    key: key,
    load: async (key: string, fn: (check: () => void) => Promise<T>) => {
      const uniqueRequestId = Math.random();
      currentRequestId.current = uniqueRequestId;

      const checkStillRelevant = () => {
        if (currentRequestId.current !== uniqueRequestId) {
          throw NOT_RELEVANT_ANYMORE_ERROR;
        }
      };

      setLoading(true);
      setKey(key);
      try {
        const result = await fn(checkStillRelevant);
        checkStillRelevant();
        setLoading(false);
        setValue(result);
        setErr(undefined);
      } catch (e) {
        if (e === NOT_RELEVANT_ANYMORE_ERROR) {
          return;
        }
        console.error(e);
        setLoading(false);
        setValue(undefined);
        setErr(e);
      }
    }
  };
}