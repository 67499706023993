import React from "react";
import {AppBar, Box, Container, Toolbar, Typography} from "@material-ui/core";
import {Styled} from "../utils/Styled";

export const WikidataAppBar = () =>
  <Box flexGrow={1}>
    <AppBar position="static">
      <Container>
        <Box mx={-3}><Toolbar>
          {/*<Styled mr={2}><IconButton edge="start" color="inherit" aria-label="menu">*/}
          {/*  <Menu />*/}
          {/*</IconButton></Styled>*/}
          <Styled flexGrow={1}><Typography variant="h6">
            Art+Life
          </Typography></Styled>
        </Toolbar></Box>
      </Container>
    </AppBar>
  </Box>;