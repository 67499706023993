import React from "react";
import {useLoader} from "./Loader";
import {encodeURLWithParams} from "../utils/Encode";
import {
  Box,
  GridList,
  GridListTile,
  GridListTileBar,
  LinearProgress,
  Paper,
  Typography, useMediaQuery,
  useTheme
} from "@material-ui/core";
import {Styled} from "../utils/Styled";
import {WikidataLiteral, WikidataResult} from "./Types";
import LazyLoad from 'react-lazyload';

import 'iv-viewer/dist/iv-viewer.css';

const FullScreenViewer = require('iv-viewer').FullScreenViewer;



interface ArtistData {
  name: string,
  description: string
}

interface ArtData {
  title: string,
  url: string,
  year: number,
  links: number
}

interface RenderPainting {
  title: string,
  url: string,
  cols: number
}

interface RenderPlan {
  years: Array<{
    year: number;
    paintings: RenderPainting[];
    hiddenPaintings: RenderPainting[];
  }>;
}

function renderPlanFromArt(art: ArtData[]): RenderPlan {
  art.sort((x, y) => (y.links - x.links));

  const years = Array.from(new Set(art.map(x => x.year).filter(x => !isNaN(x))));
  years.sort((x, y) => x - y);
  years.push(NaN);

  const yearsIdx = new Map();

  const result: RenderPlan = {
    years: years.map((y, idx) => {
      yearsIdx.set(y, idx);
      return {year: y, paintings: [], hiddenPaintings: []};
    })
  };

  const iToCols = (i: number) => {
    if (i < 3) {
      return 8;
    } else if (i < 15) {
      return 4;
    } else if (i < 63) {
      return 2;
    }
    return 1;
  };

  art.filter(x => x.url).forEach((a, i) => {
    const yearData = result.years[yearsIdx.get(a.year)];
    const collection = iToCols(i) > 1 ? yearData.paintings : yearData.hiddenPaintings;
    collection.push({
      url: a.url,
      cols: iToCols(i),
      title: a.title,
    });
  });

  result.years = result.years.filter(x => (x.paintings.length + x.hiddenPaintings.length) > 0);

  return result;
}

const ArtistArt = ({artistId}: {artistId: string}) => {
  const artLoader = useLoader<RenderPlan>();

  if (artLoader.key !== artistId) {
    artLoader.load(artistId, async (check) => {
      const artQuery = `
        SELECT 
          ?itemLabel 
          (MIN(?pic) as ?url)
          (MIN(?inceptionDate) as ?inception)
          (COUNT(?o1) as ?links)
        WHERE 
        {
          ?item wdt:P170 wd:${artistId}.
          ?item wdt:P31 wd:Q3305213.
        
          OPTIONAL {
            ?item wdt:P571 ?inceptionDate.
            ?item wdt:P18 ?pic.
            ?item ?p1 ?o1.
          }
          
          SERVICE wikibase:label { bd:serviceParam wikibase:language "[AUTO_LANGUAGE],en". }
        } GROUP BY ?item ?itemLabel
      `;

      const artResponse = await fetch(encodeURLWithParams(
        'https://query.wikidata.org/bigdata/namespace/wdq/sparql',
        {query: artQuery, format: 'json', origin: '*'}));
      check();
      const artJson = (await artResponse.json()) as WikidataResult<{
        itemLabel?:WikidataLiteral;
        url?:WikidataLiteral;
        inception?:WikidataLiteral;
        links?:WikidataLiteral;
      }>;
      check();

      const result = artJson.results.bindings.map((raw): ArtData => {
        return {
          title: raw.itemLabel ? raw.itemLabel.value : '',
          url: raw.url ? raw.url.value : '',
          year: raw.inception && raw.inception.value.length > 4
            ? parseInt(raw.inception.value.substr(0, 4))
            : NaN,
          links: raw.links ? parseInt(raw.links.value) : NaN
        };
      });

      return renderPlanFromArt(result);
    });
  }

  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  let colDiv = 4;
  if (isUpMd) {
    colDiv = 1;
  } else if (isUpSm) {
    colDiv = 2;
  }

  const columns = (c:number) => Math.ceil(c / colDiv);

  const renderPlan = artLoader.loaded && artLoader.value;
  const renderedArt = artLoader.loaded && renderPlan ?
    <>{ renderPlan.years.map(yearData => <Box pb={3} key={`year-${yearData.year}`}>
      <Styled pb={1}><Typography variant={"h3"}>
        {isNaN(yearData.year) ? 'Unknown' : yearData.year}
      </Typography></Styled>
      <GridList cellHeight={160} cols={columns(8)}>
        {yearData.paintings.map(painting => (
          <GridListTile key={`painting-${painting.url}`}
                        cols={columns(painting.cols)} rows={columns(painting.cols)}
                        style={{cursor: "pointer"}}
            onClick={ () => {
              new FullScreenViewer().show(
                `${painting.url}?width=${120 * columns(painting.cols)}`,
                painting.url
              );
            }}>
            <LazyLoad offset={600}>
              {/*<a href={painting.url} target="_blank">*/}
                <Box bgcolor="text.disabled" style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                  <img src={`${painting.url}?width=${120 * columns(painting.cols)}`} style={{
                    // width: "auto",
                    // height: "auto",
                    // maxWidth: "100%",
                    // maxHeight: "100%",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }} alt={painting.title}/>
                </Box>
              {/*</a>*/}
            </LazyLoad>
            <GridListTileBar
              title={painting.title}
            />
          </GridListTile>
        ))}
      </GridList>
      {yearData.hiddenPaintings.length > 0
        ? <Typography variant="body1">+ {yearData.hiddenPaintings.length} more</Typography>
        : <></>}
    </Box>)}</> : <></>;

  return <>
    { artLoader.loading
      ? <LinearProgress variant="query" />
      // : <Box bgcolor="primary.main" ml={0} mt={2}><Box ml={0.5} pl={2.5} bgcolor="white">{renderedArt}</Box></Box>}
      : renderedArt}
  </>
};

export const Artist = ({artistId}: {artistId: string}) => {
  const artistLoader = useLoader<ArtistData>();

  if (artistLoader.key !== artistId) {
    artistLoader.load(artistId, async (check) => {

      const artistNameQuery = `
        SELECT ?name ?description
        WHERE 
        {
          SERVICE wikibase:label { bd:serviceParam wikibase:language "en".
            wd:${artistId} rdfs:label ?name.
            wd:${artistId} schema:description ?description.
          }
        }
      `;

      const artistNameResponse = await fetch(encodeURLWithParams(
        'https://query.wikidata.org/bigdata/namespace/wdq/sparql',
        {query: artistNameQuery, format: 'json', origin: '*'}));
      check();
      const artistNameJson = (await artistNameResponse.json()) as WikidataResult<{
        name: WikidataLiteral,
        description: WikidataLiteral
      }>;
      check();

      if (artistNameJson.results.bindings.length === 0) {
        return {name: "", description: ""};
      }

      const artist = artistNameJson.results.bindings[0];
      return {
        name: artist.name.value,
        description: artist.description.value
      };
    });
  }

  const artist = artistLoader.value;

  const artistInfo = artistLoader.loaded && artist ?
    <>
      <Typography variant="h2">
        {artist.name}
      </Typography>
      <Typography variant="subtitle1">
        {artist.description}
      </Typography>
      <ArtistArt artistId={artistId}/>
    </> : <></>;

  return <>
    <Styled px={3} py={3} my={3}><Paper>
      { artistLoader.loading
        ? <LinearProgress variant="query" />
        : artistInfo}
    </Paper></Styled>
  </>;
};
