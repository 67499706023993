import React from 'react';
import './App.css';
import {Wikidata} from "./wikidata/Wikidata";
import {CssBaseline} from "@material-ui/core";
import {BrowserRouter} from "react-router-dom";

const App: React.FC = () => {
  return <BrowserRouter>
    <CssBaseline/>
    <Wikidata />
  </BrowserRouter>
};

export default App;
