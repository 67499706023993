import React from "react";
import {Autocomplete} from "@material-ui/lab";
import {CircularProgress, Paper, TextField} from "@material-ui/core";
import {Styled} from "../utils/Styled";
import {sleep} from "../utils/Sleep";
import {encodeURLWithParams} from "../utils/Encode";
import {useLoader} from "./Loader";
import {WikidataLiteral, WikidataResult} from "./Types";

interface SearchQueryResult {
  search: WikidataSearchEntity[];
}

interface WikidataSearchEntity {
  id: string;
  label: string;
  description?: string;
}

interface ArtistData {
  id: string;
  name: string;
}

async function loadAutocomplete(query: string, check: () => void): Promise<WikidataSearchEntity[]> {
  if (query === '') {
    return [];
  }

  await sleep(400);
  check();

  const searchResponse = await fetch(encodeURLWithParams(
    'https://www.wikidata.org/w/api.php',
    {
      action: 'wbsearchentities',
      format: 'json',
      origin: '*',
      language: 'en',
      limit: '50',
      search: query,
    }));
  check();
  const searchJson = (await searchResponse.json()) as SearchQueryResult;
  check();

  const ids = searchJson.search.map((x) => `wd:${x.id}`).join(',');
  const painterFilterQuery = `
      SELECT ?item
      WHERE 
      {
        ?item wdt:P106 wd:Q1028181
        filter (?item IN (${ids})).
      }`;

  const painterFilterResponse = await fetch(encodeURLWithParams(
    'https://query.wikidata.org/bigdata/namespace/wdq/sparql',
    {query: painterFilterQuery, format: 'json', origin: '*'}));
  check();
  const painterFilterJson = (await painterFilterResponse.json()) as WikidataResult<{item: WikidataLiteral}>;
  check();
  const painterIds = new Set(painterFilterJson.results.bindings.map(
    ({item}) => item.value.split('/').pop()));
  return searchJson.search.filter(x => painterIds.has(x.id));
}

export const SearchBar = ({onArtistPick}:{
  onArtistPick?: (artist: ArtistData) => void
}) => {
  const autocompleteLoader = useLoader<WikidataSearchEntity[]>();
  const completeData = autocompleteLoader.value || [];

  const autocomplete = (query: string) => {
    autocompleteLoader.load(query, check => loadAutocomplete(query, check));
  };

  return <>
    <Styled p={3} my={3}><Paper>
      <Autocomplete
        id="search-bar"
        options={completeData}
        getOptionLabel={(option: WikidataSearchEntity) => `${option.label} (${option.description})`}
        loading={autocompleteLoader.loading}
        onChange={(event, value?: WikidataSearchEntity) => {
          if (value && onArtistPick) onArtistPick({id: value.id, name: value.label});
          if (document.activeElement) {
            (document.activeElement as any)?.blur();
          }
        }}
        style={{width: '100%'}}
        renderInput={params => (
          <TextField
            {...params}
            label="Search" variant="outlined" fullWidth
            onChange={(event) => autocomplete(event.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {autocompleteLoader.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Paper></Styled>
  </>;
};
