import React from "react";
import {WikidataAppBar} from "./WikidataAppBar";
import {SearchBar} from "./SearchBar";
import {Container} from "@material-ui/core";
import {withRouter, useParams, Switch, Route} from "react-router-dom";
import {Artist} from "./Artist";

const ArtistWithId = () => <Artist artistId={useParams<{id:string}>().id}/>;

export const Wikidata = withRouter(({history}) =>
  <>
    <nav>
      <WikidataAppBar/>
    </nav>
    <main>
      <Container>
        <SearchBar
          onArtistPick={({id, name}) =>
            history.push(`/painter/${id}/${name.replace(/\W+/g, "_")}`)
          }
        />
        <Switch>
          <Route path="/painter/:id" children={<ArtistWithId />}/>
        </Switch>
      </Container>
    </main>
  </>);
